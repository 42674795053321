<script setup lang="ts">
import { Ref, computed } from '@vue/reactivity';
import { defineProps, onMounted, reactive, ref, watch } from 'vue';
import LeftArrow from './LeftArrow.vue';
import RightArrow from './RightArrow.vue';

let carouselRef = ref(null);
let imagesRef = ref(null);
let preloadedImage: any = ref(null);
let state = reactive({ width: 0, height: 0 });
let props = defineProps<{
  images: CarouselImage[];
  currentIndex: number;
  imageClickable?: boolean;
  fillScreen?: boolean;
}>();

let emit = defineEmits<{
  (e: 'update:currentIndex', index: number): void;
  (e: 'imageClicked'): void;
}>();

let isReady = computed(() => state.width && state.height);

let currentContainerRatio = () =>
  state.height == 0 || state.width == 0 ? 1 : state.width / state.height;

let imageHeight = computed(() => {
  if (preloadedImage && imagesRef.value) {
    let { naturalHeight, naturalWidth } = imagesRef.value as HTMLImageElement;

    if (naturalWidth / naturalHeight >= currentContainerRatio()) {
      return (state.width / naturalWidth) * naturalHeight;
    } else {
      return state.height;
    }
  }

  return 0;
});

let imageWidth = computed(() => {
  if (preloadedImage && imagesRef.value) {
    let { naturalHeight, naturalWidth } = imagesRef.value as HTMLImageElement;
    console.log(imagesRef);

    if (naturalWidth / naturalHeight >= currentContainerRatio()) {
      return state.width;
    } else {
      return (state.height / naturalHeight) * naturalWidth;
    }
  }

  return 0;
});

const getNextIndex = (offset: 1 | -1) => {
  return offset + props.currentIndex < 0
    ? props.images.length - 1
    : offset + props.currentIndex == props.images.length
    ? 0
    : offset + props.currentIndex;
};

watch(props, () => {
  updatePreloadImage(props.currentIndex);
});

const updatePreloadImage = (currIndex: number) => {
  preloadedImage.value = null;
  const img = props.images[currIndex];
  if (img) {
    let preloadImg = new Image();
    preloadImg.src = img.src;
    preloadImg.onload = () => {
      preloadedImage.value = preloadImg;
    };
  }
};

onMounted(() => {
  // Find parent element to define height and width for the carousel.
  if (carouselRef.value) {
    let { clientHeight, clientWidth } = (carouselRef.value as HTMLElement)
      .parentElement!;
    state.width = clientWidth;
    state.height = clientHeight;
  }

  updatePreloadImage(props.currentIndex);
});
</script>

<template>
  <section ref="carouselRef">
    <div
      class="wrapper"
      :style="{ alignItems: !!fillScreen ? 'inherit' : 'center' }"
    >
      <img
        v-if="preloadedImage"
        :src="preloadedImage.src"
        :height="imageHeight"
        :width="imageWidth"
        @click="emit('imageClicked')"
        ref="imagesRef"
        :style="{
          cursor: imageClickable ? 'pointer' : 'inherit',
          userSelect: 'none',
        }"
      />
      <div v-if="!preloadedImage" class="spinner-border" role="status">
        <span class="visually-hidden">Loading..</span>
      </div>
    </div>
    <slot name="caption">
      <div class="caption" v-if="images.length > 0">
        <div class="caption-text">
          {{ props.images[currentIndex].caption }} ({{
            `${currentIndex + 1}/${props.images.length}`
          }})
        </div>
      </div>
    </slot>
    <div
      class="leftButton"
      @click="emit('update:currentIndex', getNextIndex(-1))"
    >
      <div class="icon-wrapper">
        <LeftArrow />
      </div>
    </div>
    <div
      class="rightButton"
      @click="emit('update:currentIndex', getNextIndex(1))"
    >
      <div class="icon-wrapper"><RightArrow /></div>
    </div>
  </section>
</template>

<style scoped>
section {
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
}

.hidden {
  display: none;
}

.caption {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.caption-text {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 6px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.leftButton {
  position: absolute;
  z-index: 1;
  bottom: 0;
  top: 0;
  left: 0;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.rightButton {
  position: absolute;
  z-index: 1;
  bottom: 0;
  top: 0;
  right: 0;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

img {
  max-width: 100%;
  object-fit: cover;
}
</style>

<script lang="ts">
export interface CarouselImage {
  src: string;
  caption?: string;
}
</script>